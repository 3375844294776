<template>
  <div class="card card-body shadow-sm page_sale_detail">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.SALE_LIST_DETAIL_CUSTOMER(customer_id, apartment_id)"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row mt-2 c_filter_area mb-3">
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                  <button type="button" :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getPrevMonth"><i class="fa fa-angle-left c_prev_btn"></i>{{ $t('sale.prevMonth') }}</button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                  <month-picker-input
                    ref="month"
                    :class="['c_sale_picker', isDisableButton ? 'c_disable' : '']"
                    lang="ja"
                    :default-month="selectMonth.month"
                    :default-year="selectMonth.year"
                    @change="changeMonth"
                    :show-year="true"
                    input-pre-filled
                    date-format="%Y年%n"
                  >
                  </month-picker-input>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                  <button type="button" :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getNextMonth">
                    {{ $t('sale.nextMonth') }}<i class="fa fa-angle-right c_next_btn"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-md-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.apartment_id_equal.label }}</label>
                <span input-style="normal">
                  <input name="apartment_name" disabled v-model="apartment_name" class="full-width app-input">
                </span>
              </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.purchase_type_equal.label }}</label>
                <app-select :name="filters.purchase_type_equal.name"
                            input-style="normal"
                            v-model="filters.purchase_type_equal.value"
                            :options-data="meta.purchaseTypes"
                />
              </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.customer_name_like.label }}</label>
                <span input-style="normal">
                  <input name="customer_name" disabled v-model="customer_name" class="full-width app-input">
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:table-menu-right>
          <button @click="handleBtnDownload()" class="btn btn-primary">
            {{ $t("sale.download") }}
          </button>
        </template>
        <template v-slot:body-cell-detail="props">
          <td class="app-align-middle text-center app-p-none c_w_10_percent">
            <button
              @click="handleBtnDetailClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("sale.detail") }}
            </button>
          </td>
        </template>
        <template v-slot:body-cell-purchase_type="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.purchase_type" class="app-table-p app-cell-tooltip mb-0">
              {{ getPurchaseType(props.row) }}
            </p>
          </td>
        </template>
        <template v-slot:body-cell-amount="props">
          <td class="app-align-middle text-right">
            <p :data-original-title="props.row.amount" class="app-table-p app-cell-tooltip mb-0">
              {{ convertFormatMoney(props.row.amount) }}
            </p>
          </td>
        </template>
        <template v-slot:body-cell-purchase_status="props">
          <td class="app-align-middle text-left">
            <p :data-original-title="props.row.purchase_status" :class="props.row.purchase_status == 2 ? 'app-table-p app-cell-tooltip mb-0 text-red':'app-table-p app-cell-tooltip mb-0'">
              {{ getPurchaseStatus(props.row.purchase_status) }}
            </p>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import {ENDPOINT} from "../../../constants/api";
import {MonthPickerInput} from 'vue-month-picker'
import moment from 'moment'

import * as echarts from 'echarts';
import AppBasicTable from "../../../components/_common/list/AppBasicTable";
import AppInput from "../../../components/forms/AppInput";

export default {
  name: "SaleListPage",
  components: {
    AppInput,
    "app-basic-table": AppBasicTable,
    MonthPickerInput,
  },
  data() {
    const customer_id = this.$route.params.customer_id;
    const apartment_id = this.$route.params.apartment_id;
    const monthDefault = this.$route.query['filters.month.equal'];
    return {
      monthDefault: monthDefault,
      monthSelect: moment().format('YYYY/MM/01'),
      selectMonth: {
        month: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).month() + 1 : moment().month() + 1,
        year: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).year() : moment().year(),
      },
      columns: [
        {name: "no", label: this.$t("sale.no"), sortable: false, textAlign: 'text-center', class: 'c_w_40'},
        {name: "use_at", label: this.$t("sale.purchased_at"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "purchase_type", label: this.$t("sale.purchase_type"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "customer_id", label: this.$t("sale.customer_id"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "customer_name", label: this.$t("sale.customer_name"), sortable: true, textAlign: 'text-left', class: 'c_w_40'},
        {name: "amount", label: this.$t("sale.amount"), sortable: true, textAlign: 'text-right', class: 'c_w_40'},
        {name: "purchase_status", label: this.$t("sale.status"), sortable: true, textAlign: 'text-center', class: 'c_w_10_percent'},
      ],
      filters: {
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("customers.apartment")
        },
        purchase_type_equal: {
          name: "purchase_type",
          condition: "equal",
          label: this.$t("sale.purchase_type")
        },
        month: {
          name: "month",
          condition: "equal",
          label: this.$t("sale.month")
        },
        customer_name_like: {
          name: "customer_name",
          condition: "like",
          label: this.$t("sale.customer_name")
        },
      },
      customer_id: customer_id,
      apartment_id: apartment_id,
      apartment_name: '',
      customer_name: '',
      meta: {
        apartments : [],
        purchaseTypes : [],
        purchaseStatus : [],
        customer_name : '',
      },
      isDisableButton : false
    };
  },
  methods: {
    onResetAllSearch() {
      let currentMonth = moment(JSON.parse(localStorage.getItem('paramsFillterPreviousCustomer'))['filters.month.equal'])
      this.selectMonth.month = currentMonth.month() + 1
      this.selectMonth.year = currentMonth.year()
      this.filters.month.value = currentMonth.format('YYYY/MM/01')
      this.$refs.month.selectedDate = currentMonth.format('YYYY年M月')
      this.filters.purchase_type_equal.value = 'all'
    },
    onSearch() {
      // this.getTourSelectName();
    },
    getPurchaseType(row) {
      if (row.purchase_type == 1) return 'レストラン利用';
      if (row.purchase_type == 2) return '売店利用';
      if (row.purchase_type == 3) return 'ルームサービスの注文';
      let facilityText = this.meta.purchaseTypes.find(({id}) => id === row.facility_id);
      if (facilityText == undefined) {
        return '';
      }
      return facilityText.name;
    },
    convertFormatMoney(amount) {

      return '￥' + amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    getPurchaseStatus(status) {
      let getPurchaseText = this.meta.purchaseStatus.find(({id}) => id === status);
      if (getPurchaseText == undefined) {
        return '';
      }
      return getPurchaseText.name;
    },
    async getPrevMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let prevMonth = moment(currentMonth).subtract(1, 'months')
      this.selectMonth.month = prevMonth.month() + 1
      this.selectMonth.year = prevMonth.year()
      console.log(prevMonth.format('YYYY/MM/01'));
      this.filters.month.value = prevMonth.format('YYYY/MM/01')
      this.$refs.month.selectedDate = prevMonth.format('YYYY年M月')
    },
    async changeMonth(dataSelect) {
      this.selectMonth = {
        month: dataSelect.monthIndex,
        year: dataSelect.year,
      }
      let currentMonth = dataSelect.year + '/' + dataSelect.monthIndex + '/01'
      this.filters.month.value = currentMonth
      this.$refs.month.selectedDate = moment(currentMonth).format('YYYY年M月')
    },
    async getNextMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let nextMonth = moment(currentMonth).add(1, 'months')
      this.selectMonth.month = nextMonth.month() + 1
      this.selectMonth.year = nextMonth.year()
      console.log(nextMonth.format('YYYY/MM/01'));
      this.filters.month.value = nextMonth.format('YYYY/MM/01')
      this.$refs.month.selectedDate = nextMonth.format('YYYY年M月')
    },
    async handleBtnDownload() {

      let endpoint = ENDPOINT.SALE_LIST_DETAIL_CUSTOMER_DOWNLOAD;
      let params = {
        'apartment_id': this.apartment_id,
        'customer_id': this.customer_id,
      }
      const filtersClone = _.cloneDeep(this.filters)

      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'all') {
          params[`filters[${filter.name}][${filter.condition}]`] = filter.value
        }
      })
      if(this.$route.query.sortBy != 'undefined'){
        params['sortBy'] = this.$route.query.sortBy;
      }
      if(this.$route.query.sortType != 'undefined'){
        params['sortType'] = this.$route.query.sortType;
      }
      const res = await this.$request.get(endpoint, params)
      if (!res.hasErrors()) {
        this.urlFileDownloadCustomer = res.data.result
        var fileURL = this.urlFileDownloadCustomer;
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
    }
  },
  mounted: function () {
    let paramsFillterPreviousCustomer = {
      'filters.month.equal': this.$route.query['filters.month.equal'],
    }
    localStorage.setItem('paramsFillterPreviousCustomer', JSON.stringify(paramsFillterPreviousCustomer));
    this.$request.get(this.ENDPOINT.UI_HELPER_SALES, {apartment_id: this.apartment_id }).then(res => {
      let apartments = _.cloneDeep(res.data.apartments);
      let purchaseTypes= _.cloneDeep(res.data.purchaseTypes);
      let purchaseStatus = _.cloneDeep(res.data.purchaseStatus);
      apartments.unshift({
        id: 'all',
        name: '',
      });
      this.meta = {
        apartments: apartments,
        purchaseTypes: purchaseTypes,
        purchaseStatus: purchaseStatus,
      }
      let apartmentText = apartments.find(({id}) => id == this.$route.params.apartment_id);
      this.apartment_name = apartmentText.name;
    })
    this.$request.get(this.ENDPOINT.UI_HELPER_SALES_CUSTOMER(this.customer_id)).then(res => {
      let customerName = _.cloneDeep(res.data);
      this.customer_name = customerName;
    });
  },
}
</script>

<style>
  .page_sale_detail table tr td p{
    min-height: 45px!important;
    display: flex;
    align-items: center;
  }
  .page_sale_detail table tr td.text-center p{
    justify-content: center;
  }
  .page_sale_detail table tr td.text-right p{
    justify-content: end;
  }
</style>
